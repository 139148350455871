
import { defineComponent, toRefs, ref } from "vue";
import useFAndL from "@/hooks/payment/bu/useFAndL";
import useTableHeight from "@/utils/payment/useTableHeight";
import YearPicker from "../../components/YearPicker.vue";
import TableWrapper from "../../components/TableWrapper.vue";
import downloadFile from "@/utils/payment/downloadFile";
export default defineComponent({
  name: "F&L",
  props: {},
  setup(props, context) {
    const {
      column,
      tableData,
      query,
      pagination, // 分页
      pageChange, // 切换分页
      sizeChange,
      search,
      reset,
      getRowClass,
      options,
      statusList, // 查询条件状态列表
      roundList, // round 列表
      searchRound, // 查询round列表
      codeList, // code列表
      searchCode, // 查询code
    } = useFAndL();
    const { tableHeight } = useTableHeight(options);
    const handleDownLoad = (payoutCode: string) => {
      const params = {
        url: `/ptapi/detail/excel?payoutCode=` + payoutCode,
        method: "post",
        fileName: payoutCode,
      };
      downloadFile(params);
    };
    return {
      column,
      tableData,
      query,
      ...toRefs(pagination),
      pageChange,
      sizeChange,
      search,
      reset,
      getRowClass,
      tableHeight,
      ...toRefs(options),
      statusList, // 查询条件状态列表
      roundList, // round 列表
      searchRound,
      codeList, // code列表
      searchCode, // 查询code
      handleDownLoad, // 下载
    };
  },
  components: {
    YearPicker,
    TableWrapper,
  },
});
