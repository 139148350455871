import { computed, onMounted, reactive, ref, Ref, watch } from 'vue';
import moment from 'moment';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import useMock from '@/utils/payment/useMock';
import useStripedRowClass from '@/utils/payment/useStripedRowClass';
import useThousandsAndTwoDecimal from '@/utils/payment/useThousandsAndTwoDecimal';
import useThousands from '@/utils/payment/useThousands';
import Data from '@/views/Payment/BU/F&L/mock';
import QueryMock from '@/views/Payment/BU/F&L/queryMock';
import CodeMock from '@/views/Payment/BU/F&L/codeMock';
import RoundMock from '@/views/Payment/BU/F&L/roundMock';
import { useStore } from 'vuex';
import {
    getBuFlPayoutOverviewList,
    getDealerPayoutCondition,
    getRoundListByDate,
    getCodeListByRound,
} from '@/API/payment';
import { DataProps } from '@/views/Payment/BU/F&L/type';
import { LegalEntity } from '@/views/User/types';
const useDealerNo = () => {

  const { state } = useStore()
  //#region [初始化]
  // 表头
  const column = ref([
    {
      title: "Payout Round",
      dataIndex: "payoutRound",
      width: 300,
    },
    {
      title: "Payout Code",
      className: 'column-no-wrap',
      width: 280,
      dataIndex: "payoutCode",
      slots: { customRender: "payoutCode" }
    },
    {
      title: "VIN Number",
      dataIndex: "vinNo",
      customRender: useThousands(),
      align: "right",
      width: 150,
    },
    {
      title: "Payout Amount w VAT",
      dataIndex: "payoutAmountWVat",
      customRender: useThousandsAndTwoDecimal(),
      width: 300,
      align: "right",
    },
    {
      title: "Latest Update Time",
      dataIndex: "updatedAt",
      align:'center'
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
    },
  ]);
    // 配置信息
    const options = reactive({
        isPagination: true,
        isTabBar: true,
        searchRowNum: 2,
    });
    // 查询条件
    const query = reactive({
        year: '',
        payoutRound: null,
        payoutCodeList: [],
        statusList: [],
    });
    // 分页
    const pagination = reactive({
        total: 0,
        pageNumber: 1,
        pageSize: 10,
    });
    // 斑马纹
    const getRowClass = useStripedRowClass<DataProps>();
    //#endregion

    // 获取表格数据
    const res = ref<any>();
    const search = () => {
        // useMock<DataProps>(Data, query).then((data) => {
        //   res.value = data;
        // });
        getBuFlPayoutOverviewList({
            year: moment(query.year).format('YYYY'),
            month: 0,
            payoutCodeList:
                query.payoutCodeList.length > 0 ? query.payoutCodeList : null,
            payoutRound: query.payoutRound,
            statusList: query.statusList.length > 0 ? query.statusList : null,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }).then((data) => {
            res.value = data;
            pagination.total = data.totalElements;
        });
    };

    const sizeChange = (current: number, size: number) => {
        pagination.pageSize = size;
        search();
    };
    const tableData = computed(() => {
        return res.value?.content;
    });
    // 初始化查询条件
    const queryRes = ref<any>();
    const roundRes = ref<any>();
    const codeRes = ref<any>();
    const statusList = computed(() => {
        return queryRes.value?.financeStatusList;
    });
    const roundList = computed(() => {
        return roundRes.value;
    });
    function unique(arr: string[]) {
        if (!arr) {
            return null;
        }
        const arr1: string[] = [];
        for (let i = 0; i < arr.length; i++) {
            if (!arr1.includes(arr[i])) {
                // 检索arr1中是否含有arr中的值
                arr1.push(arr[i]);
            }
        }
        return arr1;
    }
    const codeList = computed(() => {
        return unique(codeRes.value);
        // return codeRes.value;
    });

    const initCondition = () => {
        return new Promise((resolve) => {
            getDealerPayoutCondition().then((res) => {
                queryRes.value = res;
                query.year = res.year.toString();
                resolve(res);
            });
        });
    };

    // 查询round列表
    const searchRound = () => {
        query.payoutRound = null;
        query.payoutCodeList = [];
        roundRes.value = null;
        codeRes.value = null;
        const entityName = state.user.LegalEntity?.entityName;
        let type = 'FINANCE';
        if (entityName === 'MBLC') {
            type = 'LC';
        } else if (entityName === 'MBAFC') {
            type = 'AFC';
        }
        return getRoundListByDate({
            params: {
                month: 0,
                year: moment(query.year).format('YYYY'),
                type,
            },
        }).then((res) => {
            roundRes.value = res;
            query.payoutRound = res.length ? res[0] : undefined; 
        });
        // useMock(RoundMock).then((res) => {
        //   roundRes.value = res
        // })
    };
    // 查询code列表
    const searchCode = () => {
        if (query.payoutRound === '') {
            codeRes.value = null;
            query.payoutCodeList = [];
            return;
        }
        query.payoutCodeList = [];
        codeRes.value = null;
        return getCodeListByRound(query.payoutRound).then((res) => {
            codeRes.value = res;
            // codeRes.value = ['bbbb'];
        });
        // if (!query.payoutRound) {
        //   codeRes.value = null;
        // }
        // useMock(CodeMock).then((res) => {
        //   codeRes.value = res;
        // });
    };
    // 初始化后查询表格及round列表
    initCondition().then(() => {
        return searchRound();
    }).then(() => {
        return searchCode();
    }).then(() => {
        search();
    });
    // 重置
    const reset = () => {
        query.year = '';
        query.payoutRound = null;
        query.payoutCodeList = [];
        query.statusList = [];
        queryRes.value = null;
        roundRes.value = null;
        codeRes.value = null;
        // 分页重置
        pagination.total = 0;
        pagination.pageNumber = 1;
        pagination.pageSize = 10;
        initCondition().then(() => {
            search();
            searchRound();
        });
    };
    const pageChange = (val: number) => {
        pagination.pageNumber = val;
        search();
    };
    return {
        column,
        tableData,
        query,
        // 分页
        pagination,
        pageChange, // 切换分页
        sizeChange, // 切换分页大小
        search,
        reset,
        getRowClass,
        options,
        // 初始化查询条件
        statusList,
        roundList,
        searchRound,
        codeList,
        searchCode,
    };
};
export default useDealerNo;
