import { onMounted, onUnmounted, ref, unref } from 'vue'
const useTableHeight = (options: {
    isPagination: boolean;
    isTabBar: boolean;
    searchRowNum: number;
}) => {
  // 自适应表格高度
  const tableHeight = ref<number>(0);
  const setTableHeight = () => {
    const _height = document.body.clientHeight - 260 - 5;
    tableHeight.value = options.isPagination ? _height - 50 : _height;
    tableHeight.value = options.isTabBar ? unref(tableHeight) - 50 : unref(tableHeight);
    tableHeight.value = options.searchRowNum ? 
    unref(tableHeight) - 50 * options.searchRowNum : unref(tableHeight);
  };
  onMounted(() => {
    setTableHeight();
    // 添加 resize 绑定事件
    window.addEventListener("resize", setTableHeight);
  });
  onUnmounted(() => {
    window.removeEventListener("resize", setTableHeight);
  });
  return {
    tableHeight
  }
};
export default useTableHeight;
